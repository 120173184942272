function Avatar(props) {
  const {
    className = '',
    children = null,
    ...rest // To handle all unhandled props so far
  } = props;

  return (
    <span
      className={`inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500 ${className}`}
      {...rest}>
      <span className="text-sm font-medium leading-none text-white">{children}</span>
    </span>
  );
}

export default Avatar;
