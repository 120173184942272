const Loading = (props) => {
  const { src = <></>, className } = props;

  return (
    <div className="flex justify-center items-center h-screen" style={{ height: '50vh' }}>
      <img src={src} className={className} />
    </div>
  );
};

export default Loading;
