import axios from 'axios';
import baseUrl from '@root/config';

export const configToken = {
  headers: {
    ...(sessionStorage.getItem('token') && {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    })
  }
};

export const getQuery = (url, args = 'populate=*', config = configToken) => {
  return axios
    .get(baseUrl + url + '?' + args, config)
    .then((res) => {
      // Check if there are other pages
      const current_page = res?.data?.meta?.pagination?.page;
      const total_page = res?.data?.meta?.pagination?.pageCount;
      const queries = [res];

      const nbLoop = current_page && total_page ? total_page - current_page : 0;

      // Prepare the queries accordingly
      for (let i = 1; i <= nbLoop; i++) {
        queries.push(axios.get(baseUrl + url + `?pagination[page]=${i + 1}&` + args, config));
      }

      // Wait for them to be resolved
      return Promise.all(queries);
    })
    .then((arr) =>
      arr.length == 1
        ? arr?.[0]?.data
        : // We reassemble them as if it was one result data
          {
            data: arr?.map((o) => o?.data?.data)?.flat()
          }
    );
};

export const postQuery = (url, arr, config = configToken) =>
  Promise.all(
    arr.map((element) => {
      delete element.id;
      return axios.post(`${baseUrl}${url}`, { data: element }, config);
    })
  );

export const putQuery = (url, arr, config = configToken) =>
  Promise.all(
    arr.map((element) => axios.put(`${baseUrl}${url}/${element?.id}`, { data: element }, config))
  );

export const deleteQuery = (url, arr, config = configToken) =>
  Promise.all(arr.map((element) => axios.delete(`${baseUrl}${url}/${element?.id}`, config)));
