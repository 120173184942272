import { useContext } from 'react';
import ContextDarkMode from './ContextDarkMode';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

function DarkModeButton(props) {
  const { darkMode, setDarkMode } = useContext(ContextDarkMode);
  const {
    className = '',
    labelDisplay = false,
    labelText = ['Dark mode', 'Light mode'],
    labelPos = 'right'
  } = props;

  const iconArr = [
    <MoonIcon key={0} className="h-4 w-4" />,
    <SunIcon key={1} className="h-4 w-4 " />
  ];

  const position = labelPos == 'right' ? 'flex-row-reverse' : '';

  return (
    <button
      className={
        'flex gap-0.5 items-center justify-center overflow-hidden text-sm font-medium transition rounded-lg dark:bg-zinc-900 py-1 px-3 hover:bg-zinc-300 dark:hover:bg-gray-200 dark:text-gray-200 dark:hover:text-gray-900 ' +
        className
      }
      onClick={setDarkMode}>
      <div className={`flex gap-1 items-center justify-center ${position}`}>
        {/* Number(Boolean) -> 0 or 1 */}
        {labelDisplay && labelText?.[Number(darkMode)]}
        {iconArr?.[Number(darkMode)]}
      </div>
    </button>
  );
}

export default DarkModeButton;
