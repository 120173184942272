import { useState } from 'react';
import ContextDarkMode from './ContextDarkMode';

/* Color mode : same principle, switch case depend on what you chose */

const DarkMode = (props) => {
  const { children = null } = props;
  const [darkMode, setDarkMode] = useState(localStorage.getItem('color-theme') === 'dark');

  document.documentElement.classList.toggle('dark', darkMode);

  const updateDarkMode = () => {
    const newValue = !darkMode;

    document.documentElement.classList.toggle('dark', newValue);
    localStorage.setItem('color-theme', newValue ? 'dark' : 'light');
    setDarkMode(newValue);
  };

  return (
    <ContextDarkMode.Provider
      value={{
        updateDarkMode: () => updateDarkMode(),
        darkMode
      }}>
      <div className="transition">{children}</div>
    </ContextDarkMode.Provider>
  );
};

export default DarkMode;
