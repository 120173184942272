import SingleCombobox from './SingleCombobox';
import MultipleCombobox from './MultipleCombobox';

const Combobox = (props) => {
  const { multiple = false, ...rest } = props;

  if (multiple) return <MultipleCombobox {...rest} />;
  else return <SingleCombobox {...rest} />;
};

export default Combobox;
