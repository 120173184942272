import React from 'react';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TabNavigator = (props) => {
  const { tabs = [], className = '' } = props;

  return (
    <div className={'bg-white ' + className}>
      <div className="block px-4 sm:px-6 lg:px-8 h-full">
        <nav className="-mb-px flex space-x-8 h-full" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={tab.href}
              state={tab.state}
              className={classNames(
                tab.active
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center'
              )}>
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default TabNavigator;
